const QA_DEFAULTS = [
  'future',
  'next',
  'try',
  'localhost',
  'corp-staging',
  'corp.rei.com',
  'reioms.stage-oms',
  'training.next-rentals',
  'rei-blog-develop',
  'rei-cloud',
  'rei.porbeta.com',
];

const PROD_DEFAULT = [
  'www.rei.com',
  'destination.rei.com',
  'reioms.ourgiftcards.com',
  'rentals.rei.com',
];

export {
  QA_DEFAULTS,
  PROD_DEFAULT,
};
